<template>
    <div class="gril-center">
        <div class="page-screen">
            <div>
                <div class="iconfont icon-sousuo"></div>
                <div>{{$t('grilList.search')}}</div>
                <div>
                    <el-select v-model="sex" size="mini" style="width:120px" :placeholder="$t('grilList.searchSelect')">
                        <el-option :label="$t('grilList.sexTwo')" value="women"></el-option>
                        <el-option :label="$t('grilList.sexOne')" value="men"></el-option>
                    </el-select>
                    <el-input size="mini" style="width:200px;margin-left:20px" :placeholder="$t('grilList.searchNickName')" v-model="nickName"></el-input>
                </div>
                <div @click="goSousuo">{{$t('grilList.nowSearch')}}</div>
            </div>
        </div>
        <div>
            <div 
                @click="goUserXQ(item)"
                @mouseover="girlPoShow = index" 
                @mouseleave="girlPoShow = 1000"  
                v-for="(item,index) in grilList" 
                :key="index" 
                class="girl-item">
                <div class="shiming" v-if="item.isTrueName == 1">{{$t('userLeft.cardReal')}}</div>
                <transition name="el-fade-in-linear">
                    <div class="girl-item-p" v-if="girlPoShow == index">
                        <el-tooltip class="item" effect="dark" :content="$t('grilList.btnChat')" placement="top">
                            <i @click.stop="isChat(item)" class="iconfont icon-liaotian"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="$t('grilList.btnLove')" placement="top">
                            <i @click.stop="isCollect(item)" class="iconfont icon-shoucang"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="$t('grilList.btnGift')" placement="top">
                            <i @click.stop="isGifts(item)" class="iconfont icon-gift-fill"></i>
                        </el-tooltip>
                    </div>
                </transition>
                <div class="usre-logo-img">
                    <img :src="$isApi.http + item.header" alt="">
                </div>
                <div class="girl-item-user">
                    <span>{{item.nickName}}</span>
                    <img v-if="item.level == 1" class="vip-imgs" :src="vipImg" alt="">
                    <img v-if="item.level == 2" class="vip-imgs" :src="svipImg" alt="">
                    <i v-if="item.sex == 'women'" class="iconfont icon-xingbienv1"></i>
                    <i v-else style="color:#fff;background:#4A79CC" class="iconfont icon-xingbienan1"></i>
                    <span style="color:#0ACE00;flex:1;text-align:right" v-if="item.isLine">{{$t('grilList.onLine')}}</span>
                    <span style="color:#f00;flex:1;text-align:right" v-else>{{$t('grilList.offLine')}}</span>
                </div>
                <div class="girl-item-text">
                    <span v-if="item.age">{{item.age}}</span>
                    <span v-else>{{$t('grilList.noText')}}</span>
                    <span v-if="item.city">{{item.city}}</span>
                    <span v-else>{{$t('grilList.noText')}}</span>
                    <span v-if="item.height">{{item.height}}</span>
                    <span v-else>{{$t('grilList.noText')}}</span>
                </div>
                <div class="girl-item-say" v-if="item.signature">{{item.signature}}</div>
                <div class="girl-item-say" v-else>{{$t('grilList.noContent')}}</div>
            </div>
        </div>
        <el-pagination
            v-if="pageShow"
            class="page-size-number"
            background
            @current-change="currentChange"
            :page-size="pageSize"
            :current-page="pageNo"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: ["pageShow"],
    data(){
        return{
            btnShow: true,
            grilNumber: 0,
            girlPoShow: 1000,
            pageNo: 1,
            pageSize: 20,
            grilList: [],
            sendForm: {},
            total: 0,
            sex: "women",
            nickName: "",
            setGiftUserForm: {},
            vipImg: require("../../assets/images/vip.png"),
            svipImg: require("../../assets/images/SVIP.png"),
            manLogo: require('../../assets/images/user_logo.jpg'),
            womenLogo: require('../../assets/images/que.jpg')
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))
        this.$nextTick(()=>{
            if(this.sendForm.sex == 'men'){
                this.sex = 'women'
                this.grilL({
                    nickName: "",
                    sex: "women",
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                })
            }else{
                this.sex = 'men'
                this.grilL({
                    nickName: "",
                    sex: "men",
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                })
            }
        })
    },
    methods: {
        goSousuo(){
            this.grilL({
                nickName: this.nickName,
                sex: this.sex,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            })
            this.btnShow = false
        },
        qinchu(){
            this.nickName = ""
            this.sex = "women"
            this.$nextTick(()=>{
                if(this.sendForm.sex == 'men'){
                    this.grilL({
                        nickName: "",
                        sex: "women",
                        pageNo: this.pageNo,
                        pageSize: this.pageSize
                    })
                }else{
                    this.grilL({
                        nickName: "",
                        sex: "men",
                        pageNo: this.pageNo,
                        pageSize: this.pageSize
                    })
                }
                this.btnShow = true
            })
        },
        grilL(json){
            this.$isAxios.axiosGet(this.$isApi.findOpposite + this.pageShow.url,json,(res)=>{
                if(res.data.result){
                    this.total = res.data.total
                    if(res.data.result.length > 0){
                        this.grilList = res.data.result
                        this.grilNumber = res.data.result.length
                    }
                }else{
                    this.grilList = []
                }
            })
        },
        // 跳转用户详情
        goUserXQ(item){
            sessionStorage['userId'] = item.userId
            this.$nextTick(()=>{
                this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:item.userId,visitorId:this.sendForm.userId},(fang)=>{
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                })
            })
        },
        // 创建弹出聊天
        isChat(item){
            this.$isChat.newChat(this,item)
        },
        // 添加喜欢
        isCollect(item){
            this.$isAxios.axiosGet(this.$isApi.findUserLike,{userId:this.sendForm.userId,beLikedId:item.userId,isRead:false},(like)=>{
                if(like.data.message == 'success'){
                    if(like.data.result){
                        this.$message(this.$i18n.t('grilList.addGril'))
                    }else{
                        var json = {
                            userId: this.sendForm.userId,
                            beLikedId: item.userId,
                            isRead: false
                        }
                        this.$isAxios.axiosPost(this.$isApi.addMyLike,json,(res)=>{
                            if(res.data.message == 'success'){
                                this.$message({
                                    message: this.$i18n.t('grilList.addSuccess'),
                                    type: 'success'
                                })
                            }
                        })
                    }
                }
            })
        },
        // 跳转礼品
        isGifts(item){
            if(this.sendForm.level != 0){
                this.$store.state.setGiftUserForm = item
                this.$store.state.giftShow = true
            }else{
                this.$confirm(this.$i18n.t('login.shenVipState2'), this.$i18n.t('giftComponents.reminder'), {
                    confirmButtonText: this.$i18n.t('userLeft.success'),
                    cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.$store.state.sayShow = false
                    this.$nextTick(()=>{
                        this.$store.state.upgradeShow = true
                    })
                })
            }
        },
        // 分页
        currentChange(e){
            var json = {
                nickName: this.nickName,
                sex: this.sex,
                pageNo: e,
                pageSize: this.pageSize
            }
            this.grilL(json)
        },
        goLoveWall(){
            this.$router.push({
                path: "/page/Love"
            })
        }
    }
}
</script>

<style scoped>
.vip-imgs{
    widows: 14px;
    height: 14px;
    margin-left: 4px
}
.shiming{
    position: absolute;
    right: 10px;
    top: 10px;
    background: #0f0;
    background-image: linear-gradient(135deg, #ffcc00, #e400ff);
    padding: 4px 10px;
    width: max-content;
    height: max-content;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    border-radius: 4px;
}
.gril-center{
    height: max-content;
}
.gril-center>div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px
}
.gril-center>div:nth-child(2){
    display: flex;
    height: max-content;
    flex-wrap: wrap;
}
.girl-item{
    width: 192px;
    height: max-content;
    background: #fff;
    flex-grow: 0;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .2);
    border-top-left-radius: 14px;
    border-bottom-right-radius: 14px;
    margin: 0 20px 20px 0;
    /* background-image: linear-gradient(135deg, #ffcc00, #e400ff); */
    background: #fff;
    padding-bottom: 10px;
    position: relative;
    cursor: pointer;
}
.girl-item-p{
    width: 187px;
    height: 200px;
    background: rgba(0, 0, 0, .6);
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    border-top-left-radius: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.girl-item-p>i{
    color: #fff;
    font-size: 30px;
}
.girl-item-p>i:nth-child(2){
    margin: 0 10px;
}
.girl-item-p>i:hover{
    background-image: -webkit-linear-gradient(135deg,#ffcc00, #e400ff);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
}
.usre-logo-img{
    width: calc( 100% - 6px );
    height: 200px;
    background: #fff;
    border: 3px solid #fff;
    border-top-left-radius: 14px;
    overflow: hidden;
    box-shadow: 2px 2px  6px rgba(0, 0, 0, .1)
}
.usre-logo-img>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.girl-item>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.girl-item-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    padding: 0 10px
}
.girl-item-user>span:nth-child(1){
    color: rgba(0, 0, 0, .6);
    font-size: 16px;
    font-weight: bold;
    height: 16px;
    line-height: 16px
}
.girl-item-user>span:nth-child(1):hover{
    color: #f00;
    text-decoration: underline
}
.girl-item-user>span:nth-child(3){
    flex: 1;
    font-size: 12px;
    font-weight: bold;
    text-align: right
}
.girl-item-user>i{
    height: 22px;
    width: 22px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    color: #fff;
    background: #FD78C2;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
}
.girl-item-text{
    padding: 0 10px;
    display: flex;
    height: 12px;
    margin-top: 4px
}
.girl-item-text>span{
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    color: #999;
    display: block;
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #999
}
.girl-item-text>span:last-child{
    border: none
}
.girl-item-say{
    padding: 0 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
    margin-top: 4px;
    color: #999;
    height: 35px;
    line-height: 16px
}
.girl-item-say:hover{
    text-decoration: underline
}
</style>
