<template>
    <div class="gifs">
        <div class="gifs-center">
            <div class="gift-center-title">
                <div>
                    <span @click="giftTap(1)" :class="{activat: isActivat == 1}">{{$t('login.giftXu')}}</span>
                    <span v-if="zhenShow" @click="giftTap(2)" :class="{activat: isActivat == 2}">{{$t('login.giftZhen')}}</span>
                </div>
                <i class="iconfont icon-guanbi" @click="$store.state.giftShow = false"></i>
            </div>
            <div class="gift-center-list">
                <div @click="goGiftUser(item)" class="gift-center-item" v-for="(item,index) in giftList" :key="index">
                    <img :src="$isApi.http + item.fileUrl" alt="">
                    <div>
                        <span>{{item.name}}：</span>
                        <span>{{item.price}}$</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="gift-center-content" v-if="loveContentShow">
            <div>
                <div class="gift-center-content-title">
                    <div>
                        <span>{{$t('giftComponents.giftGo')}}</span>
                        <span style="color:#00f;margin:0 6px">{{$store.state.setGiftUserForm.nickName}}</span>
                        <span>{{$t('giftComponents.setGo')}}</span>
                        <span style="color:#0f0;margin:0 6px">{{giftItem.name}}</span>
                    </div>
                    <i @click="loveContentShow = false" class="iconfont icon-guanbi colse-icon-x"></i>
                </div>
                <el-input
                    class="gift-center-content-textarea"
                    type="textarea"
                    :rows="2"
                    :placeholder="$t('giftComponents.content')"
                    v-model="words">
                </el-input>
                <el-button @click="giftFoUser" class="gift-center-content-btn" size="small" type="success">{{$t('giftComponents.successGo')}}</el-button>
            </div>
        </div>
        <transition name="el-zoom-in-top">
            <div class="gift-type-2" v-if="giftFormShow">
                <div>
                    <div class="gift-type-2-name">{{giftItem.name}}</div>
                    <el-image 
                        fit="scale-down"
                        class="gift-type-2-img"
                        :src="$isApi.http + giftItem.fileUrl" 
                        :preview-src-list="[$isApi.http + giftItem.fileUrl]">
                    </el-image>
                    <div class="gift-type-2-list">
                        <div class="gift-type-2-item">
                            <span>{{$t('login.qian_01')}}：</span>
                            <span style="color:#f00;font-weight:bold">{{giftItem.price}}$</span>
                        </div>
                        <div class="gift-type-2-item">
                            <span>{{$t('login.qian_02')}}：</span>
                            <span>{{giftItem.introduce}}</span>
                        </div>
                        <div class="gift-type-2-item1">
                            <span>{{$t('login.qian_03')}}：</span>
                            <div>
                                <el-input 
                                    type="textarea" 
                                    :rows="2" 
                                    v-model="words" 
                                    :placeholder="$t('giftComponents.content')">
                                </el-input>
                            </div>
                        </div>
                        <div class="gift-type-2-item" style="margin-top:10px">
                            <span></span>
                            <span>
                                <el-button size="small" @click="giftFoUser" type="success">{{$t('giftComponents.setGo')}}</el-button>
                                <el-button size="small" @click="giftFormShow = false">{{$t('login.qian_04')}}</el-button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { setTimeout } from 'timers';
export default {
    data(){
        return{
            giftFormShow: false,
            sendForm: {},
            loveContentShow: false,
            giftItem: {},
            words: "",
            isActivat: 1,
            giftList: [],
            giftType_2: {},
            zhenShow: true
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        if(this.sendForm.nickName == 'test1' || this.sendForm.nickName == 'test2'){
            this.zhenShow = false
        }
        this.gifLi({
            pageNo: 1,
            pageSize: 10000,
            name: "",
            type: 1,
            status: true,
            price: "",
        })
    },
    methods :{
        // 所有礼物
        gifLi(json){
            this.$isAxios.axiosGet(this.$isApi.giftShowAll,json,(res)=>{
                if(res.data.message == 'success'){
                    this.giftList = res.data.result.list
                }
            })
        },
        goGiftUser(item){
            this.words = ""
            this.price = item.price
            this.giftItem = item
            if(item.type == 2){
                this.giftFormShow = true
            }else{
                this.loveContentShow = true
            }
        },
        giftTap(num){
            this.isActivat = num
            if(num == 1){
                this.gifLi({
                    pageNo: 1,
                    pageSize: 10000,
                    name: "",
                    type: 1,
                    status: true
                })
            }else{
                this.gifLi({
                    pageNo: 1,
                    pageSize: 10000,
                    name: "",
                    type: 2,
                    status: true
                })
            }
        },
        giftFoUser(){
            if(this.sendForm.balance >= this.price){
                var json = {
                    giftId:this.giftItem.id,
                    giftPrice:this.giftItem.price,
                    senderId:this.sendForm.userId,
                    receiverId:this.$store.state.setGiftUserForm.userId,
                    words:this.words
                }
                this.$isAxios.axiosPost(this.$isApi.addUserGift,json,(res)=>{
                    if(res.data.message == 'success'){
                        if(this.isActivat == 2){
                            this.$message({
                                type: 'success',
                                message: this.$i18n.t('login.giftZhenMsg')
                            })
                        }else{
                            this.$message({
                                type: 'success',
                                message: this.$i18n.t('giftComponents.success')
                            })
                        }
                        this.loveContentShow = false
                        this.$store.state.giftShow = false
                        this.userFormXQ()
                        this.$nextTick(()=>{
                            setTimeout(()=>{
                                location.reload()
                            },2000)
                        })
                    }
                })
            }else{
                this.$confirm(this.$i18n.t('giftComponents.notSufficientFunds'), this.$i18n.t('giftComponents.reminder'), {
                    confirmButtonText: this.$i18n.t('userLeft.success'),
                    cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.$store.state.payShow = true
                })
            }
        },
        userFormXQ(){
            this.sendForm.balance = this.sendForm.balance - this.price
            this.$isAxios.axiosPost(this.$isApi.modifyUser,this.sendForm,(res)=>{
                if(res.data.result == 'OK'){
                    sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
                }
            })
        }
    }
}
</script>

<style scoped>
.gift-type-2{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.gift-type-2>div{
    width: 50vw;
    height: 80vh;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 20px 30px;
}
.gift-type-2-name{
    font-size: 18px;
    font-weight: bold;
    color: #f00;
    text-align: center
}
.gift-type-2-img{
    width: 100%;
    height: 50vh;
    background: #000;
    margin-top: 10px
}
.gift-type-2-item{
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}
.gift-type-2-item>span:nth-child(1),
.gift-type-2-item1>span:nth-child(1){
    font-size: 14px;
    display: block;
    color: #999;
    margin-right: 10px;
    width: 50px
}
.gift-type-2-item>span:nth-child(2),
.gift-type-2-item1>span:nth-child(2){
    font-size: 14px;
    height: max-content;
}
.gift-type-2-item1{
    font-size: 14px;
    height: max-content;
    display: flex;
}
.gift-type-2-item1>div{
    margin-top: 2px;
    width: 100%
}
.gift-type-2-list{
    margin-top: 20px
}
.gifs{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
.gift-center-content{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 101;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
.gift-center-content>div{
    width: 33vw;
    height: max-content;
    background: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .2);
    border-radius: 4px;
    overflow: hidden;
}
.gift-center-content-title{
    height: 48px;
    padding: 0 20px;
    background: #fd463e;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
}
.colse-icon-x{
    color: rgba(255, 255, 255, .4);
    cursor: pointer;
}
.colse-icon-x:hover{
    color: #fff;
}
.gift-center-content-textarea{
    margin: 20px;
    width: calc( 100% - 40px );
}
.gift-center-content-btn{
    margin: 0 20px 20px 20px
}

.gifs-center{
    width: 40vw;
    height: 60vh;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .2);
    position: relative;
    border-radius: 4px;
    overflow: hidden
}
.gift-center-title{
    height: 48px;
    background: #fd463e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}
.gift-center-title>div{
    display: flex;
}
.gift-center-title>div>span{
    display: block;
    padding: 0 16px;
    height: 30px;
    color: #fff;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
    cursor: pointer;
}
.activat{
    background: #c3000a;
}
.gift-center-title>i{
    display: block;
}
.gift-center-title>i{
    cursor: pointer;
    font-size: 14px;
    color: rgba(255, 255, 255, .4);
}
.gift-center-title>i:hover{
    color: #fff;
}
.gift-center-list{
    height: calc( 60vh - 78px );
    overflow-y: scroll;
    padding: 30px 40px 0 40px;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
}
.gift-center-list::-webkit-scrollbar {
    width: 2px;
}
.gift-center-list::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: rgba(0,0,0,.2);
}
.gift-center-list::-webkit-scrollbar-track {
    border-radius: 2px;
    background: rgba(0,0,0,0);
}
.gift-center-item{
    width: 10vw;
    height: max-content;
    /* margin: 0 20px 20px 0; */
    padding: 6px 0;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 30px
}
.gift-center-item:hover{
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .1)
}
.gift-center-item>img{
    width: 9vw;
    height: 9vw;
    display: block;
    margin: 0 auto 10px auto;
    background: #000;
    object-fit: cover
}
.gift-center-item>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: max-content;
    justify-content: center;
    padding: 0 6px
}
.gift-center-item>div>span{
    display: block
}
.gift-center-item>div>span:nth-child(1){
    font-size: 14px;
    color: #333;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.gift-center-item>div>span:nth-child(2){
    font-size: 16px;
    font-weight: bold;
    color: #f00;
}
</style>